<template>
  <div class="wxnotice">
    <!-- 获取微信授权 -->

    <div class="title">工作待办微信通知</div>

    <template v-if="!code">
      <div class="des first">
        点击下方"微信授权"按钮，进行工作待办微信通知开通或开通状态查询。
      </div>
      <van-button type="primary" block @click="wxAuth">微信授权</van-button>
    </template>

    <template v-else>
      <template v-if="isHasSubmit">
        <div class="result-status">{{ phone }}</div>
        <div class="result-status">微信通知，已开通成功。</div>
        <div class="result-tips">
          每日早上九点，会通过“河南郑州航空港人才”微信公众号发送工作待办通知，请勿取关公众号。若有其他问题，可联系管理员
        </div>
      </template>

      <template v-else>
        <div class="des">
          请使用在“人才一站式服务平台”申请开通账号时所用手机号。
        </div>

        <van-form
          class="user-form"
          ref="form"
          validate-first
          :show-error="false"
          label-width="70"
          :rules="rules"
        >
          <van-field
            v-model="form.phone"
            name="手机号"
            label="手机号"
            placeholder="请输入手机号"
            :rules="rules.phone"
          >
            <template #button>
              <CodeBtn
                :phone="form.phone"
                action="/pclogin/sms/sendSMSCode"
              />
            </template>
          </van-field>
          <van-field
            v-model="form.code"
            label="短信验证码"
            placeholder="请输入短信验证码"
            :rules="rules.code"
          >
          </van-field>
        </van-form>
        <van-button type="primary" block @click="submit" :loading="loading"
          >提交</van-button
        >
      </template>
    </template>

    <!-- 验证码登录验证 -->
  </div>
</template>

<script>
import CodeBtn from "@/components/CodeBtn";
export default {
  components: { CodeBtn },
  data() {
    return {
      isHasSubmit: false, // 是否提交过
      code: "",
      loading: false,
      phone: "",
      form: {
        code: "",
        h5Openid: "",
        phone: "",
        remark: "PC_ZF",
      },
      rules: {
        phone: [
          {
            required: true,
            message: "请输入手机号",
          },
          {
            pattern:
              /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/,
            message: "请检查手机号格式",
          },
        ],
        code: [
          {
            required: true,
            message: "请输入验证码",
          },
        ],
      },
    };
  },
  mounted() {
    this.getToken();
  },
  methods: {
    wxAuth() {
      const appid = process.env.VUE_APP_WXAPPID;
      const redirect_uri = encodeURIComponent(window.location.href);
      const state = "";
      const url = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appid}&redirect_uri=${redirect_uri}&response_type=code&scope=snsapi_userinfo&state=${state}#wechat_redirect`;
      window.location = url;
    },
    queryUser() {
      if (this.form.h5Openid) {
        this.loading = true;

        this.$store
          .dispatch("wxNotice/postQueryUserByOpenid", this.form.h5Openid)
          .then((res) => {
            if (res.data) {
              this.phone = res.data;
              this.isHasSubmit = true;
            }
            this.loading = false;
          })
          .catch((err) => {
            this.loading = false;
          });
      }
    },
    getToken() {
      this.code = this.$route.query.code;
      if (this.code) {
        this.loading = true;
        this.$store
          .dispatch("wxNotice/postWechatH5Openid", {
            code: this.code,
          })
          .then((res) => {
            this.loading = false;
            if (res.code != 200) return;
            this.form.h5Openid = res.data;
            this.queryUser();
          })
          .catch((e) => {
            this.loading = false;
          });
      }
    },
    submit() {
      this.$refs.form.validate().then((form) => {
        this.loading = true;
        this.$store
          .dispatch("wxNotice/postSyncUserOpenid", this.form)
          .then((res) => {
            this.isHasSubmit = true;
            this.loading = false;
            this.phone = this.form.phone;
          })
          .catch((err) => {
            this.loading = false;
          });
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.wxnotice {
  padding: 1rem 0.3rem;
  line-height: 1.5;
}
.title {
  font-size: 0.48rem;
  margin-bottom: 0.5rem;
  font-weight: bold;
}

.des {
  font-size: 0.3rem;
  color: #666;
  margin-bottom: 0.5rem;

  &.first {
    margin-bottom: 2.5rem;
  }
}

.user-form {
  margin-bottom: 0.5rem;
}

.result-status {
  font-size: 0.48rem;
  color: #2fc25b;
}

.result-tips {
  font-size: 0.25rem;
  color: #666;
}

.error-tips {
  font-size: 0.2rem;
}
</style>
